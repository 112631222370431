import type { ReactNode } from "react";
import Head from "next/head";

import {
  Box,
  ChakraComponent,
  Container,
  ContainerProps,
} from "@chakra-ui/react";

import TopNavBar from "components/layout/top-nav-bar";
import Footer from "./layout/footer";

interface MainContainerProps extends ContainerProps {
  children: ReactNode;
  title?: string;
  description?: string | null;
  image?: string | null;
}

type DivComponent = ChakraComponent<"div", {}>;

const ComponentContainer = ((props: ContainerProps) => (
  <Container as={"main"} {...props} minH={"100vh"} />
)) as DivComponent;

export default function MainContainer(props: MainContainerProps) {
  const { title, children, description, image, ...otherProps } = props;
  const defaultDesc = "NFT Marketplace for art collectors";
  const defaultTitle = "NFT One — Explore finest digital collection soon";
  const defaultImage =
    "https://nftone.io/images/default-image/meta-tag-image-default.png";
  return (
    <>
      <Head>
        <title>
          {title
            ? `${title} —  NFT One — Explore finest digital collection soon`
            : " NFT One — Explore finest digital collection soon"}
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="title"
          content={title ? `${title} — ${defaultTitle}` : defaultTitle}
        />
        <meta
          name="description"
          content={description ? description : defaultDesc}
        />

        {/* OG */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nftone.io/" />
        <meta
          property="og:title"
          content={title ? `${title} — ${defaultTitle}` : defaultTitle}
        />
        <meta
          property="og:description"
          content={description ? description : defaultDesc}
        />
        <meta property="og:image" content={image ? image : defaultImage} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nftone.io/" />
        <meta
          property="twitter:title"
          content={title ? `${title} — ${defaultTitle}` : defaultTitle}
        />
        <meta
          property="twitter:description"
          content={description ? description : defaultDesc}
        />
        <meta property="twitter:image" content={image ? image : defaultImage} />
      </Head>

      <ComponentContainer {...otherProps}>{children}</ComponentContainer>
      <Footer />
    </>
  );
}
