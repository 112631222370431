import {
  Button,
  chakra,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { MdError, MdErrorOutline } from "react-icons/md";

export const LostPage = () => {
  return (
    <Container maxW={"container.lg"}>
      <Flex
        width={"full"}
        h={"80vh"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={12}
      >
        <Stack gap={0} textAlign={"center"}>
          <Heading
            as={"h1"}
            fontSize={{ base: "8rem", md: "12rem" }}
            bgGradient={"linear(to-r, main.magenta, main.purple,main.blue)"}
            bgClip={"text"}
          >
            404
          </Heading>
          <Text fontSize={{ base: "1rem", md: "1.5rem" }}>
            You are lost 😰, please go back...
          </Text>
        </Stack>
        <Button
          as={NextLink}
          href={"/"}
          variant={"gradient-outline"}
          px={{ base: 6, md: 12 }}
          size={"md"}
        >
          Navigate back home
        </Button>
      </Flex>
    </Container>
  );
};

export const ErrorPage = ({
  errorCode,
  message,
}: {
  errorCode: string;
  message?: string;
}) => {
  return (
    <Flex
      width={"full"}
      h={"80vh"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={12}
    >
      <Stack gap={0} textAlign={"center"}>
        <Heading
          as={"h1"}
          fontSize={{ base: "8rem", md: "12rem" }}
          bgGradient={"linear(to-r, main.magenta, main.purple,main.blue)"}
          bgClip={"text"}
        >
          {errorCode}
        </Heading>
        <Text fontSize={{ base: "1rem", md: "1.5rem" }}>{message}</Text>
      </Stack>
      <Button
        as={NextLink}
        href={"/"}
        variant={"gradient-outline"}
        px={{ base: 6, md: 12 }}
        size={"md"}
      >
        Navigate back home
      </Button>
    </Flex>
  );
};

export const UnthorizedItemPage = () => {
  return (
    <Container maxW={"container.lg"}>
      <Flex
        width={"full"}
        h={"80vh"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={12}
      >
        <Stack gap={0} textAlign={"center"}>
          <Heading
            as={"h1"}
            fontSize={{ base: "8rem", md: "12rem" }}
            bgGradient={"linear(to-r, main.magenta, main.purple,main.blue)"}
            bgClip={"text"}
          >
            401
          </Heading>
          <Text fontSize={{ base: "1rem", md: "1.5rem" }}>
            There are items that do not belong to your account 😰, please go
            back...
          </Text>
        </Stack>
        <Button
          as={NextLink}
          href={"/"}
          variant={"gradient-outline"}
          px={{ base: 6, md: 12 }}
          size={"md"}
        >
          Navigate back home
        </Button>
      </Flex>
    </Container>
  );
};
