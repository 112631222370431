import { LostPage } from "components/layout/404";
import MainIndex from "components/layout/main-index";
import MainContainer from "components/main-container";
import { ReactElement } from "react";

const FourOhFourPage = () => {
  return (
    <MainContainer maxW="container.xl" padding={{ base: 0, md: 6 }}>
      <LostPage />
    </MainContainer>
  );
};

FourOhFourPage.getLayout = (page: ReactElement) => {
  return (
    <>
      <MainIndex>{page}</MainIndex>
    </>
  );
};

export default FourOhFourPage;
